.past {
  opacity: 0.5;
}

ion-card.today ion-card-header ion-card-title {
  font-weight: 800;
}

ion-card.today ion-card-header ion-card-subtitle {
  font-weight: 800;
}

ion-card.today {
  background-color: #EEFCF5; /* Light teal for "today" cards */
}

.playlist-card ion-card-header {
  position: relative; /* Ensure proper positioning of child elements */
  padding-right: 40px; /* Add space for the icon */
}

.playlist-card ion-card-title {
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
  margin-right: 10px; /* Add some space between title and icon */
}

.playlist-card ion-card-subtitle {
  margin-right: 10px;
}

.playlist-card ion-icon {
  position: absolute; /* Position the icon in the corner */
  top: 25px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
}