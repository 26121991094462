.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.spinner-wrapper ion-spinner {
    transform: scale(2.5); /* Increase the scale to make the dots bigger */
}

.chords {
    font-weight: bold;
}

.line {
    font-family: 'Inconsolata', monospace;
}

.sb-song {
    white-space: pre;
}

ion-item {
    flex-direction: row;
}

ion-item.recording {
    margin-top: 10px;
    margin-bottom: 10px;
    --border-width: 0;
    --border-color: white;
}

ion-item.recording audio {
    margin: 0 10px;
}

.recording-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.recording-cont span:first-of-type {
    margin-bottom: 5px;
}

.recording-cont span {
    display: flex;
}

.add-to-playlists .playlist-card ion-card-header {
    padding-right: 0px;
}
  
.add-to-playlists .playlist-card ion-card-title {
    margin-right: 10px;
}
  
.add-to-playlists .playlist-card ion-card-subtitle {
    margin-right: 10px;
}

@media (max-width: 600px) { /* Adjust 600px as needed for your small screen breakpoint */
    ion-reorder {
      --ion-margin-inline-end: 4px; /* Adjust the end margin for small screens */
      margin-inline-end: 4px !important;
    }

    ion-item.recording audio {
        margin: 0px;
        max-width: 250px;
    }
}

@media print {
    .xc {
        border-left: none;
    }
 }